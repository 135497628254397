<template>
<div>
    <section class="col-12 col-md-11 col-lg-10 mx-auto p-dash-cliente-master bg-login-authentic">
    <div class="col-12 col-md-12 col-lg-3 text-center pt-4">
        <img v-if="logo" :src="logo" alt="Logo" class="logo-login">
    </div>
    <div class="col-12">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-3 pt-4 order-2 order-md-2 order-lg-1">
                <div class="card card-2 mb-0" style="background-color: var(--color);">
                    <div class="card-body pb-3">
                        <a href="" title="">
                            <div class="d-dash-ps">
                                <div class="mb-3 d-ico-dash-cliente">
                                    <img src="~@/assets/img/icons/ico-cadastro.png" class="ico-dash-cliente-5" alt="Qsuíte">
                                </div>
                                <div class="">
                                    <h1 class="mb-1 font-weight-medium text-white font-17">Primeiro acesso</h1>
                                    <p class="text-white pt-3">Informe seus dados para cadastrar a sua senha de acesso ao Portal do Cliente.</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-9 order-1 order-md-1 order-lg-2 pt-4">
                <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="form-group">
                                    <label>Tipo de cadastro </label>
                                    <select class="custom-select mr-sm-2" v-model="client.type" name="type" v-validate="'required'" :class="{'is-invalid': errors.has('type')}">
                                        <option :value="null">Selecione</option>
                                        <option value="CLIENT">Cliente/Proprietário</option>
                                        <option value="LESSE">Locatário</option>
                                        <option value="LIQUIDATOR">Síndico</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 pt-3"></div>
                            <div class="col-12 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>Nome </label>
                                    <input type="text" class="form-control" v-model="client.first_name" name="first_name" v-validate="'required'" :class="{'is-invalid': errors.has('first_name')}">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>Sobrenome </label>
                                    <input type="text" class="form-control" v-model="client.last_name" name="last_name" v-validate="'required'" :class="{'is-invalid': errors.has('last_name')}">
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label>CPF </label>
                                    <input type="tel" class="form-control" value="" v-model="client.cpf" placeholder="000.000.000-00" v-mask="'###.###.###-##'" name="cpf" v-validate="'required'" :class="{'is-invalid': errors.has('cpf')}">
                                </div>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label>Data de nascimento </label>
                                    <input type="date" class="form-control"  v-model="client.birth_date" name="birth_date" v-validate="'required'" :class="{'is-invalid': errors.has('birth_date')}">
                                </div>
                            </div>
                             <div class="col-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label>Sexo </label>
                                    <select class="custom-select mr-sm-2" v-model="client.gender" v-validate="'required'" name="gender" :class="{'is-invalid': errors.has('gender')}">
                                        <option :value="null">Selecione</option>
                                        <option value="MALE">Masculino</option>
                                        <option value="FEMALE">Feminino</option>
                                        <option value="NONE">Não quero informar</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12"></div>
                            <br>
                            <div class="col-12 py-2">
                                <h4 class="card-title">Contatos</h4>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label>E-mail </label>
                                    <input type="email" class="form-control" placeholder="abc@example.com" v-model="client.email" v-validate="'required|email'" name="email" :class="{'is-invalid': errors.has('email')}">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label>Telefone </label>
                                    <input type="tel" class="form-control" placeholder="(44) 44444-4444" v-model="client.cellphone" v-validate="'required'" v-mask="'(##) ####-####'" name="cellphone" :class="{'is-invalid': errors.has('cellphone')}">
                                </div>
                            </div>
                            <div class="col-12"></div>
                            <br>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label>Senha </label>
                                    <input type="password" class="form-control" placeholder="*********" v-model="client.password" v-validate="'required|min:8'" name="password" ref="password" :class="{'is-invalid': errors.has('password')}">
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="form-group">
                                    <label>Confirmar senha</label>
                                    <input type="password" class="form-control" placeholder="*********" v-validate="'required|confirmed:password'" name="confirm_password" :class="{'is-invalid': errors.has('confirm_password')}">
                                </div>
                            </div>
                        </div>
                        <div class="form-actions">
                            <div class="text-right">
                                <button type="reset" class="btn btn-dark">Cancelar</button>
                                <button type="submit" class="btn btn-info ml-2"  style="background-color: var(--color);">Cadastrar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<section class="col-12 col-md-11 col-lg-10 mx-auto p-dash-cliente-master">
    <BaseLoginFooter />
</section>
</div>
</template>
<script>
import ClientService from "@/services/resources/ClientService";
import ThemeService from "@/services/resources/ThemeService";

const serviceTheme = ThemeService.build();
const service = ClientService.build();

export default {
    data(){
        return{
            client:{
                company_id: null,
                type: null,
                first_name: null,
                last_name: null,
                cpf: null,
                birth_date: null,
                gender: null,
                email: null,
                cellphone: null,
                password: null
            },
            logo: null,
            companies: [
                { value: null, text: 'Selecione' }
            ]
        }
    },
    methods:{
        save(){
            this.$validator.validateAll().then((result) => {
                if (result) {

                    service
                    .create(this.client)
                    .then(resp => {
                        let data = {
                            username: this.client.email,
                            password: this.client.password,
                        };

                        this.$store
                        .dispatch("loginRequest", data)
                        .then(() => {
                            this.$router.push("/dashboard");
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    });
                }

            });
        },
        fetchTheme(){
            let data = {
                domain: window.location.hostname
            };

            serviceTheme
            .search(data)
            .then(resp => {
                this.client.company_id = resp[0].company_id;
                resp.forEach(element => {
                    switch(element.key){
                        case 'SETTINGS_LOGO':
                            this.logo = element.value;
                            break;
                    }
                });
            });
        }
    },
    mounted(){
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        this.fetchTheme();
    }

}
</script>